import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaFileInvoice } from "react-icons/fa";

const FileUpload = ({ uploadedFiles, setUploadedFiles }) => {
  //   const [uploadedFiles, setUploadedFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
    },
    maxFiles: 5,
  });
  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      {!uploadedFiles.length > 0 && (
        <p>
          Arrastre y suelte archivos aquí o haga clic para explorar. (Solo 5
          archivos simultaneamente)
        </p>
      )}
      {/* <ul>
        {uploadedFiles.map((file) => (
          <li key={file.name}>{file.name}</li>
        ))}
      </ul> */}
      {
        <div className="d-flex justify-content-around">
          {uploadedFiles.map((file) => (
            <div key={file.name} className="flex items-center">
              <FaFileInvoice className="mr-2" />
              <p>{file.name}</p>
            </div>
          ))}
        </div>
      }
    </div>
  );
};
export default FileUpload;
