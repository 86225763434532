import React, { useState } from "react";
// VideoCallIcon from "@material-ui/icons/VideoCall";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useEffect } from "react";
import { Business } from "@material-ui/icons";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import { useTimer } from "react-timer-hook";
import DefaultAvatar from "../../images/profile-2.svg";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function AppointmentCard(props) {
  let history = useHistory();
  const [disableButton, setDisableButton] = useState(true);
  const [disablePayBtn, setDisablePayBtn] = useState(false);
  const [time, setTime] = useState(new Date());
  const { t } = useBDocTranslator();

  // Appointments Status Collections and Styles to apply
  const APPT_STATUS_CODES = {
    NAPPT: "text-warning",
    CAPPT: "text-success",
    IPAPPT: "text-success",
    CDAPPT: "text-info",
    CCAPPT: "text-danger",
    RSAPPT: "text-warning",
  };

  /* Function to manage the conection to the Online consultation */
  const handleJoin = () => {
    const {
      event_code,
      patient_fullname,
      password,
      providerFullname,
      appointmentId,
      profileImage,
      rating,
    } = props;
    history.push({
      pathname: "/patient/lobby",
      state: {
        event_code,
        patient_fullname,
        password,
        providerFullname,
        appointmentId,
        profileImage,
        rating,
      },
    });
  };

  /* Function to manage the button for Join on the Consultation verifing the dates */
  const handleDisable = () => {
    const { appt_date_start, appt_date_end } = props;
    const start_date = Date.parse(appt_date_start);
    const end_date = Date.parse(appt_date_end);
    const rigthNow = Date.now();
    if (appt_date_start && appt_date_end) {
      return !(rigthNow < end_date && props.appt_status_code === "CAPPT");
    }
  };

  //THIS IS A BACKUP FOR THE ACTIVE FUNCTIONALITY
  /**
  const handleDisable = () => {
    const { appt_date_start, appt_date_end } = props;
    const start_date = Date.parse(appt_date_start);
    const end_date = Date.parse(appt_date_end);
    const rigthNow = Date.now();
    const rangeToStart = 300000; // 5 minutes en milliseconds
    if (appt_date_end && appt_date_end) {
      const timetoStart = start_date - rigthNow;
      return !(
        timetoStart < rangeToStart &&
        rigthNow < end_date &&
        props.appt_status_code == "CAPPT"
      );
    }
  }; */

  /* Function to manage the route of Payment */
  const handlePayment = () => {
    const finalPrice = props.new_price != null ? props.new_price : props.price;
    const { appointmentId, patient_fullname, service_name, service_duration } =
      props;

    history.push({
      pathname: "/patient/checkout/appointment",
      state: {
        appointmentId,
        patient_fullname,
        service_name,
        service_duration,
        price: finalPrice,
      },
    });
  };

  const setTimeLeft = () => {
    const today = time ? time?.setSeconds(new Date()) : new Date();
    const endDate = Date.parse(props.appt_date_start);
    const secondsLeft = endDate - today;

    setTime(today + secondsLeft);
  };

  const MyTimer = ({ expiryTimestamp }) => {
    const { seconds, minutes, hours, days, ampm } = useTimer({
      expiryTimestamp,
    });

    return (
      <>
        <span className="font-weight-bold">
          {t("Time Left to Date")} (day, hr, min):{" "}
        </span>
        <br />
        <div className="d-flex m-2">
          <div className="timer-parent-container">
            <span className="timer-container">{days}</span>
          </div>
          <span className="dots-container">
            <span className="dots-timer"></span>
            <span className="dots-timer"></span>
          </span>
          <div className="timer-parent-container">
            <span className="timer-container">{hours}</span>
          </div>
          <span className="dots-container">
            <span className="dots-timer"></span>
            <span className="dots-timer"></span>
          </span>
          <div className="timer-parent-container">
            <span className="timer-container">{minutes}</span>
          </div>
        </div>
      </>
    );
  };

  const eventCardIcon = (type) => {
    return (
      <div className="appt-type-icon">
        {type === "VIRTUAL" ? (
          <>
            <VideocamIcon className="appt-indicator-icon" />
            <span className="appt-type-label">{type}</span>
          </>
        ) : (
          <>
            <Business className="appt-indicator-icon" />
            <span className="appt-type-label">{type}</span>
          </>
        )}
      </div>
    );
  };

  const handleDisblePayButton = () => {
    return (
      (props.event_type !== "ON-SITE" && props.appt_status_code !== "CAPPT") ||
      props.order_status === "completed"
    );
  };

  useEffect(() => {
    setDisableButton(handleDisable);
    setDisablePayBtn(handleDisblePayButton);
    setTimeLeft();
  }, []);

  return (
    <div className="d-flex justify-content-around shadow p-3 m-2 bg-white rounded-lg-custom col-md-12 col-sm-12 appt-card">
      {/* Provider side */}
      <div className="text-center col-md-3 col-sm-12">
        <>{eventCardIcon(props.event_type)}</>
        <img
          src={props.profileImage || DefaultAvatar}
          alt="avatar"
          className="rounded-circle img-fluid mw-160"
        />
        <div className="text-center">
          <h4 className="pl-2 font-weight-bold">
            <span>{props.title || "Dr. "}</span>
            {props.providerFullname || "John Smith Doe"}
          </h4>
        </div>
      </div>
      {/* section divider  */}
      <hr className="divider" />
      {/* Appointment Details Side */}
      <div className="row col-md-8 col-sm-12">
        {/* Left Column */}
        <div className="col-md-6 col-sm-12">
          <div className="col-md-12 col-sm-12">
            <p>
              <span className="font-weight-bold">{t("Service")}: </span>
              {props.service_name || "SERVICE NOT PROVIDED"}
            </p>
            <p>
              <span className="font-weight-bold">
                {t("Consultation Type")}:{" "}
              </span>
              {props.event_type || "NOT PROVIDED"}
            </p>
            <p>
              <span className="font-weight-bold">
                {t("Appointment Status")}:{" "}
              </span>{" "}
              <span
                className={`font-weight-bold ${
                  props.appt_status_code
                    ? APPT_STATUS_CODES[props.appt_status_code]
                    : "text-warning"
                }`}
              >
                {t(props.appt_status_name).toUpperCase() || "NO-STATUS"}
              </span>
            </p>
            <div className="row m-2">
              {/* If The appointments is ON SITE Hide Join Button */}
              {props?.event_type.toUpperCase() === "VIRTUAL" && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className="w-auto m-1"
                  startIcon={<VideoCallIcon />}
                  style={{ backgroundColor: "#3eb53c" }}
                  onClick={handleJoin}
                  disabled={disableButton}
                >
                  {t("Join")}
                </Button>
              )}
              {/* <Button
              variant="contained"
              color="primary"
              size="large"
              className="w-auto m-1"
              startIcon={<EditIcon />}
              style={{ backgroundColor: "#f13a53" }}
              //onClick={handleEdit}
            >
              Edit
            </Button> */}
              {props.price > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className="w-auto m-1"
                  startIcon={<AttachMoneyIcon />}
                  style={{
                    backgroundColor: props.price > 0 ? "#f13a53" : "#008d00",
                  }}
                  onClick={handlePayment}
                  disabled={disablePayBtn}
                >
                  {t("Pay")}
                  {" $ "}
                  {props.new_price != null ? props.new_price : props.price}
                </Button>
              )}
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="col-md-6 col-sm-12">
          <div className="col-md-12 col-sm-12">
            <p>
              <span className="font-weight-bold">
                {t("Appointment Date")}:{" "}
              </span>
              <br />
              <span className="font-appt-date text-success">
                {props.appt_date_start || "2020-01-01 00:00:00"}
              </span>
            </p>
            <div className="m-2 w-100">
              <MyTimer expiryTimestamp={time} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
