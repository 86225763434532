import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import prescriptionIcon from "../../images/prescription.svg";
import PrenscriptionDialog from "../prescription/PrenscriptionDialog";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "900px",
  },
}));

export default function PrescriptionModalForm(props) {
  const classes = useStyles();
  const [notes, setNotes] = useState(""); // this is the state for prescription
  const [responseMessage, setResponseMessage] = useState("");
  const userLocalState = useUserState();
  const { t } = useBDocTranslator();
  const [dispenseTo, setDispenseTo] = useState(null);
  const [pharmacy, setPharmacy] = useState(null);

  const handleNotes = (e) => {
    setNotes(e.target.value);
  };

  //Method for Save the prescription
  const handlePrescriptionSave = () => {
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/prenscriptions/create/only`,
      {
        patient_directions: notes,
        created_by: userLocalState[0].user.id,
        appointment_id: props.appointmentId,
        patient_id: props.patientId,
        dispense_from: new Date(),
        dispense_to: dispenseTo,
        pharmacy_id:
          pharmacy !== null && pharmacy !== "select" ? pharmacy : null,
        pharmacyReferalsFlag: pharmacy !== null && pharmacy !== "select",
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((response) => {
        setResponseMessage(response.data.message);
        setNotes("");
        props.handleRefreshData();
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage(error);
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          {
            <div className={classes.paper}>
              <div className="row col-md-12">
                <div className="col-md-4">
                  <img
                    src={prescriptionIcon}
                    alt="rx icon"
                    className="w-30-custom"
                  />
                </div>
                <div className="col-md-8">
                  <h3>{t("Create Prescription")}</h3>
                </div>
              </div>
              {/* Form */}
              <div className="row col-md-12">
                <PrenscriptionDialog
                  handleNotes={handleNotes}
                  prescriptionValue={notes}
                  handleSave={handlePrescriptionSave}
                  responseMessage={responseMessage}
                  setDispenseTo={setDispenseTo}
                  dispenseTo={dispenseTo}
                  setPharmacy={setPharmacy}
                  pharmacy={pharmacy}
                />
              </div>
            </div>
          }
        </Fade>
      </Modal>
    </div>
  );
}
