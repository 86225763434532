/**
 * Method Refactorized On AUG 2021
 * User: edwin.rebolledo
 * */
import React, { useEffect, useState } from "react";
import Axios from "axios";
import swal from "sweetalert";
import { useParams, useHistory } from "react-router-dom";
import PatientsForProvider from "./PatientsForProvider";
import providerIcon from "../../images/doctor.svg";
import UseUserState from "../hooks/useUserState";
import DashboardAdmin from "../administrator/DashboardAdmin";
import LoadingSpin from "../utils/Loading";
import StarsComponent from "../utils/StarsComponent";
import ProfileImg from "../../images/profile-2.svg";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Cancel as CancelIcon,
  CloudDownload as CloudDownloadIcon,
} from "@material-ui/icons";

import { Button } from "@material-ui/core";
//import Button from "@material-ui/core/Button";
import {
  CircularProgress,
  Backdrop,
  MenuItem,
  FormControl,
  TextField,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ProviderEditFromAdmin() {
  const storeState = UseUserState();
  let { id } = useParams();
  const [data, setData] = useState("");
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [identification, setIdentification] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const [country, setCountry] = useState("");
  const [summary, setSummary] = useState("");
  const [nationality, setNationality] = useState("");
  const [providerStatus, setProviderStatus] = useState("disabled"); //THIS FIELD IS RELATED TO THE PROVIDER IN GRAL TERMS.
  const [open, setOpen] = useState(false);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [imgProfile, setImgProfile] = useState("");
  const [countries, setCountries] = useState([]);
  const [issuedBy, setIssuedBy] = useState("");
  const [specialty, setSpecialty] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState([]);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [providerId, setProviderId] = useState("");
  const [medicalLicense, setMedicalLicense] = useState("");
  const [reviewStatusCode, setReviewStatusCode] = useState({
    new: false,
    code: "",
  });
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // ----------------- COUNTRIES ----------------------

  const findCountries = () => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/countries/findAll`)
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Specialties stored on BD
  const findSpecialties = async () => {
    try {
      const result = await Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/specialty/findAll`
      );
      if (result) {
        setSpecialty(result.data);
      }
    } catch (error) {
      console.error("Something wrong", error);
    }
  };

  const getWindowDimension = (e) => {
    setWidth(
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    );
    setHeight(
      window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    );
  };

  //Used by Backdrop to unlock the screen
  const handleClose = () => {
    setOpen(false);
  };

  //to handle the selected specialities selected on control
  const handleChangeSpecialty = (sp) => {
    if (sp) {
      console.log("sp", sp);
      setSelectedSpecialty(sp);
    }
  };

  async function loadDataToControls(data) {
    if (data) {
      const {
        id,
        firstName,
        lastname,
        identification,
        dateOfBirth,
        gender,
        country,
        nationality,
        phone1,
        identificationType,
        summary,
        medical_license,
        issued_by,
        medical_speciality,
        user: {
          user_images,
          email,
          status_user: { name },
        },
        status_provider: { code },
      } = data;
      setfirstName(firstName);
      setLastname(lastname);
      setIdentification(identification);
      setDateOfBirth(dateOfBirth && dateOfBirth.split("T")[0]);
      setGender(gender);
      setCountry(country);
      setNationality(nationality);
      setProviderId(id);
      setPhone(phone1);
      setIdentificationType(identificationType);
      setSummary(summary);
      setIssuedBy(issued_by);
      setMedicalLicense(medical_license);
      await findSpecialties();
      setSelectedSpecialty([medical_speciality]);
      setImgProfile(
        user_images?.length > 0 ? user_images[0].path_resource : ProfileImg
      );
      setEmail(email);
      setReviewStatusCode({ new: false, code: code });
      setProviderStatus(name);
    }
  }

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    setImgLoading(true);
    getWindowDimension();
    findCountries();

    async function fetchProviderData() {
      const result = await Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/provider/review/${id}`,
        {
          headers: {
            Authorization: "Bearer " + storeState[0].jwt, //the jwt is a variable which holds the token
          },
        }
      );
      setData(result.data);
      return result;
    }

    async function fetchPatientRelated(userId) {
      const result = await Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/patient/forProvider?providerId=${id}&createdBy=${userId}`,
        {
          headers: {
            Authorization: "Bearer " + storeState[0].jwt, //the jwt is a variable which holds the token
          },
        }
      );
      // filter the result for non null values
      const filteredResult = result.data.filter((patient) => patient !== null);
      return filteredResult;
    }

    async function processRequest() {
      const dataProvider = await fetchProviderData();
      await loadDataToControls(dataProvider.data);
      const patients = await fetchPatientRelated(dataProvider?.data?.user?.id);
      setPatients(patients);
      setImgLoading(false);
      setOpen(false);
    }

    processRequest();
    setLoading(false);
  }, [statusUpdated]);

  /**
   * Update Application Status By ReviewCode
   */
  const handleUpdateAppStatus = async () => {
    if (reviewStatusCode.new) {
      try {
        const params = { id: providerId, requestStatus: reviewStatusCode.code };
        await Axios.put(
          `${process.env.REACT_APP_API_URL_BASE}/provider/updateApplicationStatus`,
          params,
          {
            headers: {
              Authorization: "Bearer " + storeState[0].jwt, //the jwt is a variable which holds the token
            },
          }
        );
      } catch (error) {
        console.log(error);
        swal(
          "Error!",
          "Please try later or contact to the Administrator",
          "warning"
        );
      }
    }
  };

  //Change the provider Status (User and access to platform)
  const handleStatusChange = () => {
    setOpen(true); //block the screen until the request
    const provider_status = providerStatus === "enabled" ? "2" : "1";

    const params = {
      email: email,
      status_user_id: provider_status, // 1 for Enable ; 2 for Disable
      rol_id: "3", // 3 For Providers
    };
    Axios.put(
      `${process.env.REACT_APP_API_URL_BASE}/users/changeStatus`,
      params,
      {
        headers: {
          Authorization: "Bearer " + storeState[0].jwt, //the jwt is a variable which holds the token
        },
      }
    )
      .then((res) => {
        setOpen(false);
        swal("Success!", "Status updated", "success");
        setStatusUpdated(!statusUpdated);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
        swal(
          "Error!",
          "Please try later or contact to the Administrator",
          "warning"
        );
      });
  };

  /**
   * Update Profile Info and Review Status if it has changed.
   */
  const handleClick = () => {
    if (
      phone &&
      firstName &&
      lastname &&
      dateOfBirth &&
      gender &&
      identification &&
      identificationType &&
      medicalLicense &&
      issuedBy &&
      summary &&
      selectedSpecialty.length > 0
    ) {
      setOpen(true);
      const specialtyArr = selectedSpecialty.map((specialty) => specialty.id);
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/provider/update`,
        {
          phone1: phone,
          firstName,
          lastname,
          dateOfBirth,
          gender,
          identificationType,
          identification,
          country,
          nationality,
          id: providerId,
          summary,
          specialty: specialtyArr,
          medical_license: medicalLicense,
          issued_by: issuedBy,
        },
        {
          headers: { Authorization: "Bearer " + storeState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          handleUpdateAppStatus();
          setOpen(false);
          swal({
            tittle: "Success!",
            text: "Profile updated successfully",
            icon: "success",
          });
        })
        .catch((error) => {
          setOpen(false);
          console.log(error);
        });
    } else {
      swal({
        tittle: "Warning!",
        text: "Required fields not filled out",
        icon: "warning",
      });
    }
  };

  return (
    <DashboardAdmin
      sectionTitle="Provider"
      description="Editing Provider"
      icon={providerIcon}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <div className="shadow-lg p-3 mb-5 bg-white rounded">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 d-flex justify-content-center">
              <div className="row container-fluid  d-flex justify-content-center">
                <div className="col-md-4">
                  <article className="text-center">
                    <div className="img-cropper my-3">
                      {imgProfile && (
                        <img
                          src={imgProfile}
                          alt="profile avatar"
                          className="profile-avatar"
                        />
                      )}
                      {imgProfile == null && (
                        <img
                          src={ProfileImg}
                          alt="profile avatar"
                          className="profile-avatar"
                        />
                      )}
                      {imgLoading && (
                        <div className="mt-3">
                          <LoadingSpin type="spokes" color="#1d3557" />
                        </div>
                      )}
                    </div>
                    <p style={{ color: "#dc3545", marginBottom: "0" }}>
                      Specialist Rank
                    </p>
                    <StarsComponent editRate={false} />
                    <fieldset className="shadow-sm p-3 mb-5 bg-white rounded row">
                      <div className="col-md-6">
                        <p className="title-activate-user">
                          Disabled | Enabled
                        </p>
                        <Switch
                          checked={providerStatus === "enabled"}
                          onChange={handleStatusChange}
                          color="primary"
                          name="activateSwitch"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                      <div className="col-md-6">
                        <p className="title-activate-user">Professional ID:</p>
                        <Button
                          variant="contained"
                          color="default"
                          startIcon={<CloudDownloadIcon />}
                          disabled={true}
                        >
                          Download
                        </Button>
                      </div>
                    </fieldset>
                  </article>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <form className="row" id="patient-form">
                <div className="form-group col-md-6">
                  <TextField
                    margin="normal"
                    label="First Name"
                    variant="outlined"
                    id="firstName"
                    fullWidth
                    onChange={(e) => setfirstName(e.target.value)}
                    required
                    value={firstName || ""}
                  />
                </div>
                <div className="form-group col-md-6">
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Last Name"
                    variant="outlined"
                    id="lastname"
                    onChange={(e) => setLastname(e.target.value)}
                    value={lastname || ""}
                  />
                </div>
                <div className="form-group col-md-12">
                  <TextField
                    required
                    margin="normal"
                    label="Your Email"
                    variant="outlined"
                    id="email"
                    value={email || ""}
                    autoComplete="email"
                    fullWidth
                    placeholder="name@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                </div>
                <div className="form-group col-md-8">
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Phone Number"
                    variant="outlined"
                    id="phoneNumber"
                    value={phone || ""}
                    placeholder="888823456"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="form-group col-md-4 ">
                  <TextField
                    id="country"
                    required
                    select
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    placeholder="Select"
                    label="Country"
                    value={country || "select"}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {countries &&
                      countries.map((singleCountry) => (
                        <MenuItem
                          key={singleCountry.id}
                          value={singleCountry.code}
                        >
                          {singleCountry.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
                <div className="form-group col-md-3">
                  <TextField
                    id="identificationType"
                    required
                    select
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    placeholder="Select"
                    label="Identification Type"
                    onChange={(e) => setIdentificationType(e.target.value)}
                    value={identificationType || "select"}
                  >
                    <MenuItem value="DNI">DNI</MenuItem>
                    <MenuItem value="CED">Cedula de Identidad</MenuItem>
                    <MenuItem value="PASSPORT">Passport</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                </div>

                <div className="form-group col-md-9">
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Identification/DNI"
                    variant="outlined"
                    id="identification"
                    onChange={(e) => setIdentification(e.target.value)}
                    value={identification || ""}
                  />
                </div>
                <div className="form-group col-md-4">
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    label="Date Of Birth"
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    value={dateOfBirth || ""}
                  />
                </div>
                <div className="form-group col-md-8">
                  <TextField
                    id="gender"
                    required
                    select
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    placeholder="Select"
                    label="Gender"
                    onChange={(e) => setGender(e.target.value)}
                    value={gender}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    <MenuItem value={"MALE"}>Male</MenuItem>
                    <MenuItem value={"FEMALE"}>Female</MenuItem>
                    <MenuItem value={"OTHER"}>Other</MenuItem>
                  </TextField>
                </div>
                {/* License and Issued By */}

                <div className="form-group col-md-6">
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Medical License"
                    variant="outlined"
                    id="medical-license"
                    onChange={(e) => setMedicalLicense(e.target.value)}
                    value={medicalLicense || ""}
                  />
                </div>

                <div className="form-group col-md-6">
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Issued By"
                    variant="outlined"
                    id="issued-by"
                    onChange={(e) => setIssuedBy(e.target.value)}
                    value={issuedBy || ""}
                  />
                </div>

                {/* End License and Issued By */}
                <div className="form-group col-md-12">
                  <TextField
                    id="summary"
                    label="Professional Summary"
                    multiline
                    fullWidth
                    rows={5}
                    value={summary}
                    variant="outlined"
                    onChange={(e) => setSummary(e.target.value)}
                  />
                </div>
                {/* Specialties */}
                <div className="form-group col-md-12">
                  <FormControl className="col-md-12">
                    <Autocomplete
                      multiple
                      id="specialty"
                      options={specialty}
                      getOptionLabel={(option) => option?.speciality_name}
                      value={selectedSpecialty}
                      isOptionEqualToValue={(option, value) => {
                        return option?.id === value?.id;
                      }}
                      onChange={(e, val, reason, details) => {
                        handleChangeSpecialty(val);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Specialty"
                          placeholder="Specialties"
                        />
                      )}
                    />
                  </FormControl>
                </div>
                {/* End Specialty */}

                {/* Provider Application Status */}
                <div className="form-group col-md-6">
                  <TextField
                    id="applicaton-status"
                    required
                    select
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    placeholder="Select..."
                    label="Application Status"
                    onChange={(e) =>
                      setReviewStatusCode({
                        new: true,
                        code: e.target.value,
                      })
                    }
                    value={reviewStatusCode.code}
                  >
                    <MenuItem value="IREV">UNDER REVIEW</MenuItem>
                    <MenuItem value="APR">APPROVED</MenuItem>
                    <MenuItem value="REJ">REJECTED</MenuItem>
                    <MenuItem value="AWA">AWAITING</MenuItem>
                  </TextField>
                </div>
                {/* End Application Status */}
              </form>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 d-flex justify-content-center ">
              <div className="p-3">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CheckCircleOutlineIcon />}
                  onClick={handleClick}
                >
                  Save
                </Button>
              </div>

              <div className="p-3">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  onClick={(e) => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingSpin type="spokes" color="#1d3557" />
        ) : (
          <PatientsForProvider data={patients} parentComponent="admin" />
        )}
      </div>
    </DashboardAdmin>
  );
}

export default ProviderEditFromAdmin;
