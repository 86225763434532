import React, { forwardRef, useState } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import NotesImages from "../../../images/notes-image.svg";
import useBDocTranslator from "../../hooks/useBDocTranslator";
import { Switch } from "@material-ui/core";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function DependantsPatient(props) {
  const { t } = useBDocTranslator();
  const [statusChanged, setStatusChanged] = useState(false);

  const handleSwitchChange = (event) => {
    props.handleIncludeBeneficiary();
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "Id", field: "id" },
          { title: t("First Name"), field: "firstName" },
          { title: t("Last Name"), field: "lastname" },
          { title: t("Created at"), field: "created_at", type: "datetime" },
          {
            title: t("Parent type"),
            field: "relationship",
            render: (rowData) => t(rowData.relationship),
          },
          {
            title: t("Beneficiary Of Plan"),
            field: "planBeneficiary",
            render: (rowData) => {
              return (
                <Switch
                  checked={rowData?.planBeneficiary}
                  onChange={(e) => {
                    const AFFILIATION_STATUS = {
                      true: "active",
                      false: "inactive",
                    };
                    props?.handleIncludeBeneficiary(
                      rowData,
                      AFFILIATION_STATUS[e.target.checked],
                      props.affiliationId
                    );
                    setStatusChanged(true);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  name="Affiliation Status"
                />
              );
            },
          },
        ]}
        data={props.data}
        title={t(props.tableTitle)}
        icons={tableIcons}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <span>
                <img style={{ width: "15%" }} src={NotesImages} alt="" />
              </span>
            ),
          },
        }}
      />
    </div>
  );
}

export default DependantsPatient;
