import React, { useEffect, useState } from "react";
import { JaaSMeeting } from "@jitsi/react-sdk";
import Loading from "../utils/Loading";
import { useHistory, useLocation } from "react-router-dom";

export default function VideoFrameV2(props) {
  const [onCall, setOnCall] = useState(false);
  const [redirectOnLeft, setredirectOnLeft] = useState(false);
  let history = useHistory();
  let location = useLocation();

  const onJitsiAPIReady = (externalApi) => {
    console.log("Jitsi API loaded", externalApi);
    externalApi.autoJoinEnabled = true;
    externalApi.executeCommand("allow", "camera; microphone");
    externalApi.on("videoConferenceLeft", () => {
      setredirectOnLeft(true);
      console.log("Left");
    });
  };

  useEffect(() => {
    if (redirectOnLeft) {
      setTimeout(() => {
        if (location.pathname.includes("patient") && props.rating === 0) {
          history.push({
            pathname: "/patient/rate/appointment",
            state: {
              appointmentId: props.appointmentId,
              providerFullname: props.providerFullname,
              profileImage: props.profileImage,
            },
          });
        } else {
          history.push("/admin/dashboard");
        }
      }, 500);
    }

    // clear timeout in case the component is unmounted before the timeout is reached
    return () => clearTimeout();
  }, [redirectOnLeft]);

  useEffect(() => {
    setOnCall(true);
  }, [onCall]);

  return onCall ? (
    <div style={{ width: "100%", height: "100vh" }}>
      <JaaSMeeting
        appId="vpaas-magic-cookie-2a621e94eb204687b967b6d149844687"
        roomName={props.eventCode}
        onApiReady={onJitsiAPIReady}
        userInfo={{
          displayName: props.displayName,
        }}
        interfaceConfigOverwrite={{
          SHOW_JITSI_WATERMARK: false,
          SHOW_DEEP_LINKING_IMAGE: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          SHOW_JITSI_PROMO: false,
        }}
      />
    </div>
  ) : (
    <>
      <Loading color="#3788d8d4" />
    </>
  );
}
