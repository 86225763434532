import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Input, MenuItem, TextField } from "@material-ui/core";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";
import FileUpload from "../utils/FileUpload";

export default function EHRFilesDialog({
  handleClose,
  successFlag,
  handleUploadEHRFile,
  uploadedFiles,
  setUploadedFiles,
}) {
  const { t } = useBDocTranslator();
  // const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    if (successFlag) {
      setUploadedFiles([]);
    }
  }, [successFlag]);

  return (
    <div className="shadow m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative dependants-card">
      <div className="col mt-3">
        <p className="lead">
          {t(
            "En esta seccion puede subir documentos clinicos a su expediente."
          )}
        </p>

        <div className="mt-2 mx-1 justify-center">
          <FileUpload
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
          />
        </div>
      </div>

      {/* Action buttons */}
      <div className="row mt-2 mx-1 mb-3">
        <Button
          variant="contained"
          color="default"
          onClick={handleClose}
          className="col-md-6"
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleUploadEHRFile()}
          className="col-md-6"
          disabled={uploadedFiles.length === 0 || successFlag}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
}
