import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import userIcon from "../../images/user-icon.svg";
import DependantsDialog from "./DependantsDialog";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import useBDocTranslator from "../hooks/useBDocTranslator";
import {
  useAffiliationsBeneficiaries,
  useCreateUsersBeneficiary,
} from "../../services/services";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  paper: {
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "900px",
    height: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
}));

export default function DependantsModalForm(props) {
  const classes = useStyles();
  const [responseMessage, setResponseMessage] = useState("");
  const userLocalState = useUserState();
  const [successFlag, setSuccessFlag] = useState(false);
  const { t } = useBDocTranslator();

  const { createUsersBeneficiary } = useCreateUsersBeneficiary();
  const { affiliateBeneficiarie, loading } = useAffiliationsBeneficiaries(
    userLocalState[0].jwt
  );

  //Method creates a dependant
  const handleDependantCreate = (data) => {
    if (!validateData(data)) {
      setResponseMessage("Error, Please fill the Form");
      return;
    }

    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/userPatient/createUserPatient`,
      data,
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((response) => {
        setSuccessFlag(true);
        setResponseMessage(
          "Dependant created, e-mail with instructions was sent to the user"
        );
        props.handleRefreshData();
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage("ERROR" + error);
      });
  };

  // validate the data object if it is valid
  function validateData(data) {
    let isValid = true;
    if (!data.email && !validateEmailFormat(data.email)) {
      isValid = false;
    }
    if (!data.firstName) {
      isValid = false;
    }
    if (!data.lastname) {
      isValid = false;
    }
    if (!data.identification) {
      isValid = false;
    }
    if (!data.relationship) {
      isValid = false;
    }
    return isValid;
  }

  // validate email format
  function validateEmailFormat(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleIncludeBeneficiary = async (data) => {
    try {
      const beneficiaryArray = [];
      beneficiaryArray.push(data);
      const beneficiaryResponse = await createUsersBeneficiary(
        beneficiaryArray
      );
      console.log({ beneficiaryResponse });
      setSuccessFlag(true);
      setResponseMessage(
        "Dependant created, e-mail with instructions was sent to the user"
      );
      if (
        Array.isArray(beneficiaryResponse?.created) &&
        props?.affiliationId &&
        data?.isBeneficiary
      ) {
        const affiliationData = beneficiaryResponse.created.map(
          (beneficiary) => {
            return {
              affiliation_id: props?.affiliationId,
              patient_id: beneficiary?.patient_id,
              status: "active",
            };
          }
        );
        // call the affiliation endpoint
        const result = await affiliateBeneficiarie(affiliationData);
        if (result.message.includes("reached")) {
          swal(
            "Error!",
            `Familiar Registrado con Error:  ${t(result?.message)}`,
            "error"
          );
          return;
        }
        swal("Exito!", "Beneficiaro agregado exitosamente", "success");
      }
      props.handleRefreshData();
    } catch (e) {
      console.log(e);
      setResponseMessage("ERROR" + e);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          {
            <div className={classes.paper}>
              <div className="row col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-3 col-sm-12">
                    <img
                      src={userIcon}
                      alt="rx icon"
                      className="w-30-custom mt-2 ml-3"
                    />
                  </div>
                  <div className="col-md-9">
                    <h3>{t("Include Family Member")}</h3>
                    <p>
                      {t(
                        "You are able to include your family member to your health-care plan"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <p
                    className={`font-weight-bold  ${
                      responseMessage.includes("ERROR")
                        ? "text-danger"
                        : "text-success"
                    } text-center`}
                  >
                    {t(responseMessage)}
                  </p>
                </div>
              </div>
              {/* Form */}
              <div className="row col-md-12">
                <DependantsDialog
                  handleDependantCreate={handleIncludeBeneficiary}
                  // prescriptionValue={notes}
                  // handleSave={handlePrescriptionSave}
                  handleClose={props.handleClose}
                  successFlag={successFlag}
                  patientId={props.patientId}
                />
              </div>
            </div>
          }
        </Fade>
      </Modal>
    </div>
  );
}
