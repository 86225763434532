// IncludeFamilyMemberForm.js

import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
// import userIcon from './path-to-user-icon'; // Replace with the actual path to your user icon
import userIcon from "../../images/user-icon.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActions, CardContent } from "@material-ui/core/";
import {
  useAffiliationsBeneficiaries,
  useCreateUsersBeneficiary,
  useGetPatientData,
  usePlanByAffiliationId,
  useStripePaymentSuccess,
} from "../../services/services";
import LoadingSpinner from "../utils/Loading";
import { useHistory } from "react-router-dom";
import InformationComp from "../../components/utils/InformationComp";
import swal from "sweetalert";
import useUserState from "../hooks/useUserState";
import { setRegistrationData, setUpdateState } from "../../actions";
import { useDispatch } from "react-redux";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    maxWidth: 600,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.grey[500],
    borderRadius: 0,
  },
}));

const IncludeFamilyMemberForm = () => {
  const classes = useStyles();
  const [responseMessage, setResponseMessage] = useState("");
  const [relationship, setRelationship] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [identification, setIdentification] = useState("");
  const history = useHistory();
  const [listBeneficiaries, setListBeneficiaries] = useState([]);
  const [blockSave, setBlockSave] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const dispatch = useDispatch();
  const [userData] = useUserState();
  const JWT = userData?.token;

  const { createUsersBeneficiary } = useCreateUsersBeneficiary();
  const { affiliateBeneficiarie, loading } = useAffiliationsBeneficiaries(JWT);
  const { changeAffiliationStatus } = useStripePaymentSuccess(JWT);
  const { getPlanByAffiliationId, beneficiaryQty } =
    usePlanByAffiliationId(JWT);
  const { patientData, getPatientData } = useGetPatientData(JWT);

  const afiliateId = userData?.user?.affiliate_id?.afiliateId;
  const parent_id = userData?.patientId;
  const subscriptionId = userData?.user?.affiliate_id?.subscription;
  const userId = userData?.user?.id;
  const beneficiariesAssigned = beneficiaryQty?.beneficiaries_assigned;
  const { t, changeDirectLanguage } = useBDocTranslator();

  // clear form
  const clearForm = () => {
    setName("");
    setLastname("");
    setEmail("");
    setIdentification("");
    setRelationship("");
    setResponseMessage("");
  };

  const handleDependantCreate = () => {
    const newBeneficiary = {
      nombre: name,
      apellido: lastname,
      email: email,
      identification: identification,
      relationship: relationship,
    };

    if (!name || !lastname || !email || !identification || !relationship) {
      return;
    }

    setListBeneficiaries([...listBeneficiaries, newBeneficiary]);
    clearForm();
  };

  function extracted() {
    return listBeneficiaries.map((beneficiary) => {
      return {
        firstName: beneficiary.nombre,
        lastname: beneficiary.apellido,
        parent_id: parent_id,
        identification: beneficiary.identification,
        relationship: beneficiary.relationship,
        email: beneficiary.email,
      };
    });
  }

  function extractedBeneficiaries(data) {
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return [];
    }

    return data.map((beneficiary) => {
      return {
        affiliation_id: afiliateId,
        patient_id: beneficiary.patient_id,
        status: "active",
      };
    });
  }

  const handleSave = async () => {
    try {
      if (listBeneficiaries?.length > beneficiaryQty?.beneficiary_qty) {
        swal("Error!", "Has alcanzado el limite de beneficiarios", "error");
        return;
      }

      const usersBeneficiary = extracted();
      const awaitCreateUsersBeneficiary = await createUsersBeneficiary(
        usersBeneficiary
      );
      const beneficiaries = await extractedBeneficiaries(
        awaitCreateUsersBeneficiary?.created
      );

      const result = await affiliateBeneficiarie(beneficiaries);
      if (result.message.includes("reached")) {
        swal("Error!", result?.message, "error");
        setListBeneficiaries([]);
        setBlockSave(true);
        return;
      }

      swal("Success!", "Beneficiaro agregado exitosamente", "success");
      setShowButton(true);
      setListBeneficiaries([]);
      await getPatientData(userData?.user?.id);
    } catch (e) {
      console.error("Error:", e);
    } finally {
    }
  };

  useEffect(() => {
    const data = {
      userId,
      sessionId: subscriptionId,
    };
    changeAffiliationStatus(data);
    getPlanByAffiliationId(afiliateId);
    changeDirectLanguage("es");
  }, []);

  const handleDeleteFamilyMember = (index) => {
    const updatedBeneficiaries = [...listBeneficiaries];
    updatedBeneficiaries.splice(index, 1);
    setListBeneficiaries(updatedBeneficiaries);
  };

  const relationshipTypes = [
    {
      value: "1",
      label: "Father",
    },
    {
      value: "2",
      label: "Mother",
    },
    {
      value: "3",
      label: "Brother",
    },
    {
      value: "4",
      label: "Sister",
    },
    {
      value: "5",
      label: "Grandfather",
    },
    {
      value: "6",
      label: "Grandmother",
    },
    {
      value: "7",
      label: "Son",
    },
    {
      value: "8",
      label: "Daughter",
    },
    {
      value: "9",
      label: "Wife",
    },
    {
      value: "10",
      label: "Husband",
    },
    {
      value: "12",
      label: "Other",
    },
  ];

  return (
    <Container component="main" maxWidth="xl" className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <Avatar alt="User Icon" src={userIcon} className="w-30-custom mb-3" />
        <Typography component="h1" variant="h5" className={"mb-3"}>
          <strong>Inclusion de Beneficiarios</strong>
        </Typography>
        <InformationComp
          style={{ width: "90%" }}
          title="¿Cómo agregar un familiar?"
          description={`Para agregar un familiar, debes completar los campos requeridos y hacer clic en el botón Agregar. Puedes agregar hasta ${
            beneficiaryQty?.beneficiary_qty
          } familiares 
                     ${
                       beneficiariesAssigned !== 0
                         ? ` y ya tienes afiliado
                      ${beneficiariesAssigned}.`
                         : "."
                     } `}
        />
        {(beneficiariesAssigned !== 0 || showButton) && (
          <Button
            className={`mt-4 mb-4`}
            type="button"
            fullWidth
            variant={"contained"}
            color="primary"
            onClick={() => {
              dispatch(
                setUpdateState({
                  ...userData,
                  extendedData: patientData,
                })
              );
              history.push("/patient/dashboard");
            }}
          >
            IR A TU CUENTA
          </Button>
        )}

        <form>
          <Grid container spacing={2} className={"mt-3"}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={name}
                variant="outlined"
                required
                fullWidth
                id="nombre"
                label="Nombre"
                name="nombre"
                autoComplete="given-name"
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={lastname}
                variant="outlined"
                required
                fullWidth
                id="apellido"
                label="Apellido"
                name="apellido"
                autoComplete="family-name"
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={email}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Correo Electronico"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={identification}
                variant="outlined"
                required
                fullWidth
                id="identification"
                label="Identificacion"
                name="identification"
                autoComplete="off"
                onChange={(e) => setIdentification(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="relationship-label">Parentesco</InputLabel>
                <Select
                  labelId="relationship-label"
                  id="relationship"
                  value={relationship}
                  onChange={(e) => setRelationship(e.target.value)}
                  label="Parentesco"
                >
                  {relationshipTypes.map((option) => (
                    <MenuItem key={option.value} value={option.label}>
                      {t(option.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            className={`mt-4 mb-2`}
            type="button"
            fullWidth
            variant={"contained"}
            color="primary"
            onClick={handleDependantCreate}
            disabled={blockSave}
          >
            {listBeneficiaries.length === 0 ? "Agregar" : "Agregar Otro"}
          </Button>
        </form>

        {listBeneficiaries.length > 0 && (
          <div className={"w-100"}>
            <Typography variant="h6" className="mt-3 mb-2 ">
              Beneficiarios
            </Typography>
            {listBeneficiaries.map((familyMember, index) => (
              <Card key={index} className={"mb-2"}>
                <CardContent>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{`Nombre: `}</strong>
                    {familyMember.nombre}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{`Apellido: `}</strong>
                    {familyMember.apellido}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{`Email: `}</strong>
                    {familyMember.email}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{`Identificador: `}</strong>
                    {familyMember.identification}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>{`Relationship: `}</strong>
                    {familyMember.relationship}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => handleDeleteFamilyMember(index)}
                  >
                    Eliminar
                  </Button>
                </CardActions>
              </Card>
            ))}
          </div>
        )}

        {loading ? (
          <div className="d-flex justify-content-center">
            <LoadingSpinner type="spinningBubbles" color="#1d3557" />
          </div>
        ) : (
          <Button
            className={`mt-4 mb-2`}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={listBeneficiaries?.length === 0 || blockSave}
          >
            Completar Seccion
          </Button>
        )}
      </Paper>
    </Container>
  );
};

export default IncludeFamilyMemberForm;
