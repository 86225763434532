import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { MenuItem, TextField } from "@material-ui/core";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function DependantsDialog(props) {
  const userLocalState = useUserState();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [identification, setIdentification] = useState("");
  const [relationship, setRelationship] = useState("");
  const { t } = useBDocTranslator();
  const [isBeneficiary, setIsBeneficiary] = useState(false);

  const relationshipTypes = [
    {
      value: "1",
      label: "Father",
    },
    {
      value: "2",
      label: "Mother",
    },
    {
      value: "3",
      label: "Brother",
    },
    {
      value: "4",
      label: "Sister",
    },
    {
      value: "5",
      label: "Grandfather",
    },
    {
      value: "6",
      label: "Grandmother",
    },
    {
      value: "7",
      label: "Son",
    },
    {
      value: "8",
      label: "Daughter",
    },
    {
      value: "9",
      label: "Wife",
    },
    {
      value: "10",
      label: "Husband",
    },
    {
      value: "12",
      label: "Other",
    },
  ];

  const data = {
    email,
    password: "12345",
    status_user_id: 1,
    person_type: "PAT",
    firstName,
    lastname,
    identification,
    parent_id: props.patientId,
    relationship:
      relationship &&
      relationshipTypes.find((type) => type.value === relationship).label,
    created_by_userId: userLocalState[0].user.id,
    isBeneficiary,
  };

  // clean values for next use
  const handleCleanValues = () => {
    setEmail("");
    setFirstName("");
    setLastname("");
    setIdentification("");
    setRelationship("");
  };

  useEffect(() => {
    if (props.successFlag) {
      handleCleanValues();
    }
  }, [props.successFlag]);

  return (
    <div className="shadow m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative">
      <div className="row">
        <div className="col-md-6 w-100 mt-2">
          <TextField
            id="firstName"
            label={t("First Name")}
            variant="outlined"
            className="w-100"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required={true}
          />
        </div>
        <div className="col-md-6 w-100 mt-2">
          <TextField
            id="lastname"
            label={t("Last Name")}
            variant="outlined"
            className="w-100"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            required={true}
          />
        </div>
        <div className="col-md-6 w-100 mt-2">
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            className="w-100"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required={true}
          />
        </div>
        <div className="col-md-6 w-100 mt-2">
          <TextField
            id="identification"
            label={t("Identification")}
            variant="outlined"
            className="w-100"
            value={identification}
            onChange={(e) => setIdentification(e.target.value)}
            required={true}
          />
        </div>
        <div className="col-md-6 w-100 mt-2">
          <TextField
            id="relationship"
            select
            label={t("Relationship")}
            variant="outlined"
            className="w-100"
            value={relationship}
            onChange={(e) => setRelationship(e.target.value)}
            required={true}
          >
            {relationshipTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {/*  Toogle to indicate if is a beneficiary */}
        <div className="col-md-6 w-100 mt-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onChange={(e) => setIsBeneficiary(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              {t("Beneficiary Of Plan")}
            </label>
          </div>
        </div>
      </div>

      <div className="row mt-2 mx-1">
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.handleDependantCreate(data)}
          className="col-md-6"
          // disabled={!btnStatus}
        >
          {t("Save")}
        </Button>
        <Button
          variant="contained"
          color="default"
          onClick={props.handleClose}
          className="col-md-6"
          // disabled={!btnStatus}
        >
          {t("Cancel")}
        </Button>
      </div>
    </div>
  );
}
