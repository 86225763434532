import React,{useState,useEffect} from "react";
import {makeStyles,Button,useTheme} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import BreadCrumbsNav from "./BreadCrumbsNav";
// import Logo from "../../images/logo-doc-nic.jpg";

import Avatar from "@material-ui/core/Avatar";
import {Link,useHistory,useParams} from "react-router-dom";
import "../../styles/customs.css";
import PatientAvatar from "../../images/paciente-avatar.svg";
import useUserState from "../hooks/useUserState";

//Icons Component
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PersonIcon from "@material-ui/icons/Person";
import HomeIcon from "@material-ui/icons/Home";
import TodayIcon from "@material-ui/icons/Today";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import PaymentIcon from "@material-ui/icons/Payment";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AppsIcon from "@material-ui/icons/Apps";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
//Connection to handle and save the message
import useMessages from "../hooks/useMessages";
import useBDocTranslator from "../hooks/useBDocTranslator";
//i need a affiliate icon from react-icons/fa
import {FaUserMd,FaFileInvoiceDollar} from "react-icons/fa";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root:{
        display:"flex",
    },
    logo:{
        width:"50px",
        height:"50px",
    },
    menuAppIcon:{
        display:"flex",
        [theme.breakpoints.down("sm")]:{
            display:"none",
        },
    },
    menuName:{
        color:"#ffffff",
        marginTop:"10px",
        [theme.breakpoints.down("sm")]:{
            display:"none",
        },
    },
    menuDivider:{
        display:"flex",
        height:"40px",
        maxHeight:"40px",
        width:"2px",
        backgroundColor:"#0c436a9e" /* #dcddde73*/,
        margin:"10px",
        marginRight:"15px",
        borderRadius:"8px",
        border:"none",
        [theme.breakpoints.down("sm")]:{
            display:"none",
        },
    },
    menu:{
        marginTop:"40px",
    },
    appBar:{
        zIndex:theme.zIndex.drawer + 1,
        backgroundColor:"#0077B6",
        transition:theme.transitions.create(["width","margin"],{
            easing:theme.transitions.easing.sharp,
            duration:theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift:{
        marginLeft:drawerWidth,
        width:`calc(100% - ${drawerWidth}px)`,
        transition:theme.transitions.create(["width","margin"],{
            easing:theme.transitions.easing.sharp,
            duration:theme.transitions.duration.enteringScreen,
        }),
    },
    hide:{
        display:"none",
    },
    drawer:{
        width:drawerWidth,
        flexShrink:0,
    },
    drawerPaper:{
        width:drawerWidth,
    },
    drawerOpen:{
        width:drawerWidth,
        transition:theme.transitions.create("width",{
            easing:theme.transitions.easing.sharp,
            duration:theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose:{
        transition:theme.transitions.create("width",{
            easing:theme.transitions.easing.sharp,
            duration:theme.transitions.duration.leavingScreen,
        }),
        overflowX:"hidden",
        width:"60px",
        [theme.breakpoints.down("sm")]:{
            width:"45px",
        },
    },
    drawerItems:{
        paddingLeft:"10px",
        [theme.breakpoints.down("sm")]:{
            paddingLeft:"0px",
        },
    },
    menuButton:{
        marginRight:40,
    },
    // necessary for content to be below app bar
    toolbar:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        padding:theme.spacing(0,1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content:{
        flexGrow:1,
        backgroundColor:theme.palette.background.default,
        padding:theme.spacing(3),
    },
    avatar:{
        //marginLeft: "50px",
        //backgroundColor: theme.palette.secondary.main,
        width:"60px",
        height:"60px",
    },
    superiorToolbar:{
        display:"flex",
        justifyContent:"space-between",
    },
    iconColor:{
        color:"#d9eef7",
    },
}));

export default function DashboardPatient(props){
    const classes = useStyles();
    let history = useHistory();
    const [open,setOpen] = useState(false);
    const theme = useTheme();
    const userState = useUserState();
    const [anchorEl,setAnchorEl] = useState(null);
    const [arrowUp,setArrowUp] = useState(false);
    const [profileImg,setProfileImg] = useState(() => {
        if(userState[0]?.user.user_images?.length > 0){
            return userState[0]?.user?.user_images?.at(-1)?.path_resource;
        }
        return PatientAvatar;
    });

    const Logo = process.env.REACT_APP_TENANT_LOGO;


    //New Messages indicator
    const {getApiMessages,badgeMessage} = useMessages("patient");

    const {t,changeDirectLanguage} = useBDocTranslator();

    const handleClick = (event) => {
        setArrowUp(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setArrowUp(false);
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const logOut = () => {
        window.sessionStorage.clear();
        history.push("/patient/login");
    };

    let {param} = useParams();

    //Effect to validate the user session
    useEffect(() => {
        //validateSession();
        // const storeOfMessages = window.sessionStorage.getItem("msg");
        // if (storeOfMessages?.length === 0) {
        // }
        getApiMessages();
        changeDirectLanguage(userState[0]?.language || "en");
    },[]);

    //To go to Text consultation screen
    const handleMessageClick = () => {
        history.push("/patient/inbox");
    };

    return (
        <div className={classes?.root} >
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={clsx(classes?.appBar,{
                    [classes?.appBarShift]:open,
                })}
                color="primary"
            >
                <Toolbar className={classes?.superiorToolbar}>
                    <div className="d-flex">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes?.menuButton,{
                                [classes?.hide]:open,
                            })}
                        >
                            <MenuIcon className={classes?.iconColor}/>
                        </IconButton>
                    </div>
                    <div className="d-flex">
                        <Button className={classes?.menuAppIcon}>
                            <AppsIcon style={{color:"white"}}/>
                        </Button>
                        <Button>
                            <NotificationsActiveIcon style={{color:"white"}}/>
                        </Button>
                        {/* Icon for Text consultations */}
                        <Button onClick={handleMessageClick}>
                            <Badge color="error" badgeContent={badgeMessage}>
                                <MailIcon style={{color:"white"}}/>
                            </Badge>
                        </Button>
                        <hr className={classes?.menuDivider}/>
                        {/* {param ? ("") : (
                         <div className="img-cropper-profile align-items-center">
                         <Avatar
                         src={userState.user.profile_image || PatientAvatar}
                         className="avatar-custom"
                         />
                         </div>
                         )} */}
                        <div className="img-cropper-profile align-items-center">
                            <Avatar src={profileImg} className="avatar-custom"/>
                        </div>

                        <div>
                            <IconButton onClick={handleClick} className="mt-2">
                                {!arrowUp ? (
                                    <KeyboardArrowDownIcon style={{color:"white"}}/>
                                ) : (
                                    <KeyboardArrowUpIcon style={{color:"white"}}/>
                                )}
                            </IconButton>
                            <Button
                                className={classes?.menuName}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                            >
                                {userState[0]?.extendedData?.firstName}{" "}
                                {userState[0]?.extendedData?.lastname}
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                className={classes?.menu}
                            >
                                <MenuItem
                                    onClick={(e) => {
                                        handleClose();
                                        history.push(`/patient/editProfile`);
                                    }}
                                >
                                    {t("edit.profile")}
                                </MenuItem>
                            </Menu>
                        </div>
                        <Button onClick={logOut}>
                            <ExitToAppIcon style={{color:"white"}}/>
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer,{
                    [classes.drawerOpen]:open,
                    [classes.drawerClose]:!open,
                })}
                classes={{
                    paper:clsx({
                        [classes.drawerOpen]:open,
                        [classes.drawerClose]:!open,
                    }),
                }}
                anchor="left"
            >
                <div className={classes.toolbar}>
                    <Avatar src={Logo} variant="square" className={classes.logo}/>

                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon className={classes.iconColor}/>
                        ) : (
                            <ChevronLeftIcon className={classes.iconColor}/>
                        )}
                    </IconButton>
                </div>
                <Divider/>
                <div className={classes.drawerItems}>
                    <List>
                        <Link to="/patient/dashboard">
                            <ListItem button>
                                <ListItemIcon>
                                    <Tooltip title={t("Home")} placement="right">
                                        <HomeIcon/>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={t("Home")}/>
                            </ListItem>
                        </Link>
                        <Link to="/patient/editProfile">
                            <ListItem button>
                                <ListItemIcon>
                                    <Tooltip title={t("my.profile")} placement="right">
                                        <PersonIcon/>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={t("profile")}/>
                            </ListItem>
                        </Link>
                        <Link to="/patient/appointments">
                            <ListItem button>
                                <ListItemIcon>
                                    <Tooltip title={t("my.appointments")} placement="right">
                                        <TodayIcon/>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={t("Appointments")}/>
                            </ListItem>
                        </Link>
                        {/* <Link to="/patient/providers">
                         <ListItem button>
                         <ListItemIcon>
                         <Tooltip title="Providers" placement="right">
                         <FaUserMd style={{ fontSize: "1.5rem" }} />
                         </Tooltip>
                         </ListItemIcon>
                         <ListItemText primary="Healthcare Providers" />
                         </ListItem>
                         </Link> */}

                        {/*-------------- textconsultation --------------*/}
                        {/*<Link to="/patient/textconsultation">*/}
                        {/*    <ListItem button>*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <Tooltip title={t("Text Consultations")} placement="right">*/}
                        {/*                <QuestionAnswerIcon/>*/}
                        {/*            </Tooltip>*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary={t("Text Consultations")}/>*/}
                        {/*    </ListItem>*/}
                        {/*</Link>*/}

                        {/*Planes*/}
                        <Link to="/patient/registration/healthPlans">
                            <ListItem button>
                                <ListItemIcon>
                                    <Tooltip title={t("My Plans")} placement="right">
                                        <FaFileInvoiceDollar style={{fontSize:"1.5rem"}}/>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={t("My Plans")}/>
                            </ListItem>
                        </Link>

                        {/* <Link to="/patient/appointments/history">
                         <ListItem button>
                         <ListItemIcon>
                         <Tooltip title="Video Consultation" placement="right">
                         <VideoCallIcon />
                         </Tooltip>
                         </ListItemIcon>
                         <ListItemText primary="Video Consultations" />
                         </ListItem>
                         </Link> */}
                    </List>
                    <Divider/>
                    <ListSubheader
                        style={{fontWeight:"bold",color:"cornflowerblue"}}
                        inset
                    >
                        {t("Configurations")}
                    </ListSubheader>
                    <List>
                        {/* <Link to="patient/settings">
                         <ListItem button>
                         <ListItemIcon>
                         <Tooltip title="Settings" placement="right">
                         <BuildIcon />
                         </Tooltip>
                         </ListItemIcon>
                         <ListItemText primary="Settings" />
                         </ListItem>
                         </Link> */}
                        <Link to="/patient/orders">
                            <ListItem button>
                                <ListItemIcon>
                                    <Tooltip title={t("Orders History")} placement="right">
                                        <PaymentIcon/>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={t("Orders History")}/>
                            </ListItem>
                        </Link>
                    </List>
                </div>

                <Divider/>
            </Drawer>
            <main className={classes.content}>
                <BreadCrumbsNav
                    breadOptions={[
                        {title:t("Home"),url:"/patient/dashboard"},
                        {title:t("Appointments"),url:"/patient/appointments"},
                        {title:t("Consultations"),url:"/patient/textconsultation"},
                    ]}
                />
                <div className={classes.toolbar}/>
                {props.children}
            </main>
        </div>
    );
}
